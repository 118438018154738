import React from 'react';
import { graphql } from 'gatsby';
import { CategoryPosts, Layout } from 'components';
// import { setupPreviewData } from '../utils/setupPreviewData';
import './category.scss';
import { TextBlock } from 'slices';

const Category = props => {
  if (!props) return null;
  const { data: staticData, location } = props;
  if (!staticData) return null;
  // const data = setupPreviewData(staticData);
  const { category, posts, footerCta } = staticData;
  if (!category) return null;
  const { data: pageData, first_publication_date: publishedDate, last_publication_date: updatedDate } = category;
  const { nodes: filteredPosts } = posts;
  const {
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    category_name: categoryName,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    publishedDate,
    updatedDate,
  };

  const categoryNameHtml = categoryName?.html;

  const { data: footerData } = footerCta;
  const { body } = footerData;
  const footerCtaData = body[0];

  return (
    <Layout location={location} seo={seo}>
      <div className="category">
        <section className="category-heading">
          <div className="wrapper">
            {categoryNameHtml && (
              <div dangerouslySetInnerHTML={{ __html: categoryName.html }} className="category-title" />
            )}
          </div>
        </section>
        <CategoryPosts posts={filteredPosts} className="category-posts" />
        {footerCtaData && <TextBlock data={footerCtaData} />}
      </div>
    </Layout>
  );
};

export default Category;

export const categoryQuery = graphql`
  query CategoryBySlug($uid: String!) {
    category: prismicCategory(uid: { eq: $uid }) {
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        category_name {
          text
          html
        }
      }
    }
    posts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        id
        data {
          category {
            uid
          }
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt {
            html
            text
          }
          timestamp(formatString: "d, MMM yyyy")
          author {
            text
            html
          }
        }
      }
    }
    footerCta: prismicSettings {
      data {
        body {
          ... on PrismicSettingsBodyTextBlock {
            id
            slice_type
            primary {
              background_colour
              content {
                html
                text
              }
              cta_link {
                url
              }
              cta_text {
                html
                text
              }
              show_logo_background
              title {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
